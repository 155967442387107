import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SoctripTranslationService {
  private languageSubject = new BehaviorSubject<any>({});
  language$: Observable<string> = this.languageSubject.asObservable();

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.languageSubject.next(event.lang);
    });
  }

  setLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    this.translateService.use(lang);
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key);
  }

  updateLocalCurrencyAndLanguage(putData: any) {
    const user_profileString = localStorage.getItem('user_profile');
    if (user_profileString !== null) {
      let user_profile: { [key: string]: string } = JSON.parse(user_profileString);
      Object.keys(putData).forEach((key: string) => {
        if (putData.hasOwnProperty(key)) {
          user_profile[key] = putData[key];
        }
      });
      localStorage.setItem('user_profile', JSON.stringify(user_profile));
    }
  }
}
