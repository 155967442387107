import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, timeout } from 'rxjs';
import { environment } from '@env/environment';
import { AuthAPI } from '../lib/api/auth/api.auth.model';
import { ApiClient } from '../lib/api/api-client';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //TODO: Replace with actual code
  basePath = 'http://172.16.8.193:3000/v1/admin';
  private api: ApiClient;


  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  login(login: any) {
    //TODO: Replace with actual code
    return this.http.post(`${this.basePath}/auth/login`, login);
  }

  refreshToken(refreshToken: any): Observable<any> {
    //TODO: Replace with actual code
    return this.http.post(`${this.basePath}/auth/refresh`, refreshToken);
  }

  getUserInfo() {
    //TODO: Replace with actual code
    return this.http.get(`${this.basePath}/auth/user-info`);
  }
  isTokenExpired(): boolean {
    const access_token_expiry_date = localStorage.getItem(
      environment.ACCESS_TOKEN_EXPIRY_KEY
    );
    const refresh_token_expiry_date = localStorage.getItem(
      environment.REFRESH_TOKEN_EXPIRY_KEY
    );
    if (access_token_expiry_date && refresh_token_expiry_date) {
      const refreshTokenExpiry = new Date(access_token_expiry_date);
      const accessTokenExpiry = new Date(refresh_token_expiry_date);
      const currentDate = new Date();
      return (
        refreshTokenExpiry < currentDate || accessTokenExpiry < currentDate
      );
    }

    return false;
  }

  logout(baseUrl: string, data: any): Observable<any> {
    return this.http.post<any>(`${baseUrl}/auth/signout`, data).pipe(
      timeout(10000),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
