import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

interface ExpandState {
  isExpand: boolean;
  isMouseOver: boolean;
}

@Component({
  selector: 'seat-selection',
  templateUrl: './seat-selection.component.html',
  styleUrls: ['./seat-selection.component.scss'],
  animations: [
    trigger('slideDownUp', [
      state('void', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
      state('*', style({ height: '*', overflow: 'hidden', opacity: 1 })),
      transition(':enter', [animate('300ms ease-out')]),
      transition(':leave', [animate('300ms ease-in')]),
    ]),
    trigger('slideInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate('300ms ease-in')]),
      transition(':leave', [animate('300ms ease-out')]),
    ]),
  ],
})
export class SeatSelectionComponent {
  @Input() segments: any;
  @Input() passengerInfo: any

  expandSeat: ExpandState = { isExpand: false, isMouseOver: false };
  segmentSeatCount: number = 0;
  selectedData: any;
  isShowSeat: boolean = true;

  constructor() {}

  ngOnInit() {}

  handleClickExpandCollapse(state: ExpandState) {
    state.isExpand = !state.isExpand;
  }

  handleMouseOverLeave(state: ExpandState, isOver: boolean) {
    state.isMouseOver = isOver;
  }
}
