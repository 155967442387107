export const ROLES = {
  user: 'user',
  manager: 'manager',
  admin: 'admin',
};
export const USER_PERMISSION = [ROLES.user, ROLES.manager, ROLES.admin];
export const MANAGER_PERMISSION = [ROLES.admin, ROLES.manager];
export const ADMIN_PERMISSION = [ROLES.admin];

export const DEFAULT_SEARCH_PARAMS = {
  page_num: 1,
  page_size: 20,
}

export const SOCTRIP_HOTLINE = '1900571248';

export const URL_COUNTRY_CODE = 'https://ipgeolocation.abstractapi.com/v1/?api_key=';
export const APIKEY_COUNTRY_CODE = 'da81649210074b6f8003c7903841046f';

export const TIME_OUT_REQUEST = 30000

export const FILTER_OPTIONS: any = [
  {
    id: 1,
    title_filter: 'Accompanying service',
    params: 'accompanying_service_list',
    is_show_filter: true,
    data_filter: [
      {
        name: 'Free breakfast',
        value: 'FREE_BREAKFAST',
        number_of_hotel: null,
      },
      {
        name: 'Discount promotion',
        value: 'DISCOUNT_PROMOTION',
        number_of_hotel: null,
      },
      {
        name: 'Free cancellation',
        value: 'FREE_CANCELLATION',
        number_of_hotel: null,
      },
      {
        name: 'No prepayment required',
        value: 'NO_PREPAYMENT_REQUIRED',
        number_of_hotel: null
      },
    ],
  },
  {
    id: 2,
    title_filter: 'Property type',
    params: 'property_type_list',
    is_show_filter: true,
    data_filter: [
      {
        id: 1,
        name: 'Homestays',
        value: 'HOMESTAY',
        number_of_hotel: 106,
      },
      {
        id: 2,
        name: 'Entire homes',
        value: 'ENTIRE_HOME',
        number_of_hotel: 1066,
      },
      {
        id: 3,
        name: 'Apartments',
        value: 'APARTMENT',
        number_of_hotel: 1098,
      },
      {
        id: 4,
        name: 'Hotels',
        value: 'HOTEL',
        number_of_hotel: 4525,
      },
      {
        id: 5,
        name: 'Resorts',
        value: 'RESORT',
        number_of_hotel: 5665,
      },
      {
        id: 6,
        name: 'Motels',
        value: 'MOTEL',
        number_of_hotel: 5665,
      },
      {
        id: 7,
        name: 'Villas',
        value: 'VILLA',
        number_of_hotel: 5665,
      },
    ],
  },
  {
    id: 3,
    title_filter: 'Distance from centre',
    params: 'distance_from_center_list',
    is_show_filter: true,
    data_filter: [
      {
        name: '<2 km from center',
        value: 'LESS_THAN_2',
        number_of_hotel: null,
      },
      {
        name: '2-5 km from center',
        value: 'TWO_TO_FIVE',
        number_of_hotel: null,
      },
      {
        name: '>5 km from center',
        value: 'GREATER_THAN_5',
        number_of_hotel: null,
      },
    ],
  },
  {
    id: 4,
    title_filter: 'Convenient',
    params: 'convenient_list',
    is_show_filter: true,
    data_filter: [
      {
        name: 'Private bathroom',
        value: 'PRIVATE_BATHROOM',
        number_of_hotel: 5665,
      },
      {
        name: 'Balcony',
        value: 'BALCONY',
        number_of_hotel: 2424,
      },
      {
        name: 'Air conditioner',
        value: 'AIR_CONDITIONING',
        number_of_hotel: 1373,
      },
      {
        name: 'View sea',
        value: 'VIEW_SEA',
        number_of_hotel: 1523,
      },
      {
        name: 'Parking',
        value: 'PARKING',
        number_of_hotel: 1803,
      },
      {
        name: 'Spa',
        value: 'SPA',
        number_of_hotel: 2710,
      },
    ],
  },
  {
    id: 5,
    title_filter: 'Reviews from customers',
    params: 'review_score_list',
    is_show_filter: true,
    data_filter: [
      {
        name: '9+ Superb',
        value: '9',
        number_of_hotel: 15,
      },
      {
        name: '8+ Excellent',
        value: '8',
        number_of_hotel: 125,
      },
      {
        name: '7+ Very good',
        value: '7',
        number_of_hotel: 85,
      },
      {
        name: '6+ Pleasant',
        value: '6',
        number_of_hotel: 485,
      },
    ],
  },
];

export const MEDIA_FILE_SIZE_LIMITS_BYTES = {
  image: 5242880,
  video: 104857600
};

export const MEDIA_FILE_FORMAT_LIMITS = {
  image: [
    'image/avif',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/jpg',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
    'image/tiff'
  ],
  video: [
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'video/3gpp',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/quicktime'
  ]
}

export const MEDIA_TYPE = {
  image: 'Image',
  video: 'Video',
  file: 'File'
};

export const TOAST_MESSAGE_LIFETIME_DURATIONS = {
  short: 2000,
  medium: 5000,
  long: 7000
};

export const TOAST_MESSAGE_SEVERITY_LEVELS = {
  warn: 'warn',
  info: 'info',
  success: 'success',
  error: 'error'
};

export const CHARACTER_TRANSLATION_LIMIT = 5000;
export const MAX_TRUNCATED_TEXT_HEIGHT = 300;
export const MAX_TRUNCATED_TEXT_HEIGHT_COMMENT = 135;
