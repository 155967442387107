<div *ngIf="fareRule" class="!p-4 bg-white rounded-lg border border-palette-gray-200 shadow-sm">
    <p class="text-palette-gray-900 text-base font-semibold !pb-1"> {{'FLIGHT.VIEW_DETAILS_SIDEBAR.FARE_RULE' | translate}}</p>
    <!-- <div id="fare_rule"></div> -->
    <ng-container [ngTemplateOutlet]="fareRuleContent" [ngTemplateOutletContext]="{
        isViewMore: false,
        isTranslate: isTranslate
        }">
    </ng-container>
</div>

<ng-template #fareRuleContent let-isViewMore="isViewMore">
    <ng-template [ngIf]="isTranslate" [ngIfElse]="notTranslate">
        <ng-template [ngIf]="translateContent()" [ngIfElse]="loadingTranslate">
            <div class="whitespace-pre-wrap text-sm font-normal fare-rule-content emoji {{
            isViewMore ? 'truncated-text' : 'block'
            }}" [ngClass]="isLiveView ? 'text-palette-base-white opacity-80' : 'text-palette-gray-900'"
                [innerHTML]="translateContent() + (isViewMore ? ' ...' : '')"></div>
        </ng-template>

        <ng-template #loadingTranslate>
            <div class="flex flex-col justify-center">
                <p-skeleton width="100px" height="12px"></p-skeleton>
                <p-skeleton class="my-1" width="100%" height="20px"></p-skeleton>
                <p-skeleton class="mb-1" width="100%" height="20px"></p-skeleton>
                <p-skeleton class="mb-1" width="100%" height="20px"></p-skeleton>
                <p-skeleton class="mb-1" width="100%" height="20px"></p-skeleton>
                <p-skeleton class="mb-1" width="100%" height="20px"></p-skeleton>
                <p-skeleton class="mb-1" width="100%" height="20px"></p-skeleton>
                <p-skeleton width="100%" height="20px"></p-skeleton>
            </div>
        </ng-template>
    </ng-template>
    <ng-template #notTranslate>
        <div class="whitespace-pre-wrap comment-content text-sm font-normal fare-rule-content emoji {{
          isViewMore ? 'truncated-text' : 'block'
        }}" [ngClass]="isLiveView ? 'text-palette-base-white opacity-80' : 'text-palette-gray-900'"
            [innerHTML]="formattedContent() + (isViewMore ? ' ...' : '')"></div>
    </ng-template>

    <div>
        <div>
            <ng-template [ngIf]="isTranslate" [ngIfElse]="viewTrans">
                <ng-container *ngIf="formattedContent()">
                    <ng-template [ngIf]="translateContent()" [ngIfElse]="loadingTranslate">
                        <div class="translate-action font-inter hover:cursor-pointer" (click)="isTranslate = false">
                            {{ 'COMMON.SEE_ORIGINAL' | translate }}
                        </div>
                    </ng-template>
                    <ng-template #loadingTranslate>
                        <div class="font-interfont-semibold hover:cursor-pointer w-fit text-palette-gray-700 text-sm mt-1">
                            {{ 'COMMON.TRANSLATING' | translate }}...
                        </div>
                    </ng-template>
                </ng-container>
            </ng-template>
            <ng-template #viewTrans>
          <ng-container *ngIf="isShowTranslateButton(fareRule)">
            <div class="font-inter translate-action hover:cursor-pointer" (click)="onTranslateText()">
              {{ 'COMMON.VIEW_TRANSLATION' | translate }}
            </div>
          </ng-container>
        </ng-template>
      </div>
      <!-- <div class="float-right">
        <ng-template [ngIf]="isViewMore" [ngIfElse]="viewLess">
          <div class="translate-action self-end" (click)="onExpandText()">
            {{ 'COMMON.VIEW_MORE' | translate }}
          </div>
        </ng-template>
        <ng-template #viewLess>
          <div
            *ngIf="(isShowAllContent || contentHeight > MAX_TRUNCATED_TEXT_HEIGHT_COMMENT) "
            class="translate-action self-end"
            (click)="onExpandText()">
            {{ 'COMMON.VIEW_LESS' | translate }}
          </div>
        </ng-template>
      </div> -->
    </div>
</ng-template>