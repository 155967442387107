<div
    class="my-price-details-section w-full min-[1100px]:w-[384px] !p-4 bg-palette-base-white border border-palette-gray-200 rounded-lg">
    <p class="text-palette-gray-900 font-inter text-base font-semibold !mb-3">
        {{'MY_FLIGHT_BOOKING_DETAIL.CONTACT_INFORMATION.TITLE' | translate}}</p>
    <div class="flex flex-col !gap-3">
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.CONTACT_INFORMATION.CONTACT_NAME' | translate}}
            </span>
            <span
                class="text-palette-gray-700 font-inter text-sm font-medium leading-5">{{flightDetail?.contact_information.title}}.
                {{flightDetail?.contact_information.full_name}}</span>
        </div>
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.CONTACT_INFORMATION.EMAIL' | translate}}
            </span>
            <span
                class="text-palette-gray-700 font-inter text-sm font-medium leading-5">{{flightDetail?.contact_information.email}}</span>
        </div>
        <div class="flex justify-between">
            <span class="text-palette-gray-500 font-inter text-sm font-medium leading-5">
                {{'MY_FLIGHT_BOOKING_DETAIL.CONTACT_INFORMATION.PHONE_NUMBER' | translate}}
            </span>
            <span
                class="text-palette-gray-700 font-inter text-sm font-medium leading-5">({{flightDetail?.contact_information.phone.phone_area_code}})
                {{flightDetail?.contact_information.phone.phone_number}}</span>
        </div>
    </div>
</div>