import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DefaultLangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit {
  data: any;
  options: any;
  dataLabels: any[] = [];
  dataColors: any[] = [];
  dataValues: any[] = [];
  totalBooking: number;
  dataReady: boolean = false;
  @Input() notePosition: string = 'right';
  @Input() chartData: any;

  constructor(private translateService: TranslateService) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.setDataForChart(changes['chartData'].currentValue);
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe((event) => {
      this.reloadLabels(this.chartData);
    });

    this.setDataForChart(this.chartData);

    this.options = {
      cutout: '75%',
      plugins: {
        legend: {
          display: false,
          position: 'right',
          align: 'start',
          title: {
            display: true,
            text: 'Coverage Rate',
            padding: 0,
          },
          labels: {
            boxWidth: 13,
            textAlign: 'left',
            padding: 10,
            usePointStyle: true,
            fontSize: 8,
          },
        },
      },
    };
  }

  reloadLabels(chartData: any) {
    this.dataLabels = [];
    chartData.data.current_items.map((item: any) => {
      this.dataLabels.push(this.getStatus(item.key));
    });
    this.data.labels = this.dataLabels;
  }

  setDataForChart(chartData: any) {
    this.dataReady = false;
    this.dataLabels = [];
    this.dataColors = [];
    this.dataValues = [];
    this.totalBooking = chartData.data.total_bookings;

    chartData.data.current_items.map((item: any) => {
      this.dataLabels.push(this.getStatus(item.key));
      this.dataColors.push(this.formatColor(item.color));
      this.dataValues.push(item.value);
    });

    this.data = {
      labels: this.dataLabels,
      datasets: [
        {
          data: this.dataValues,
          backgroundColor: this.dataColors,
          // hoverBackgroundColor: [
          //   documentStyle.getPropertyValue('--blue-400'),
          //   documentStyle.getPropertyValue('--yellow-400'),
          //   documentStyle.getPropertyValue('--green-400'),
          // ],
        },
      ],
    };
    this.dataReady = true;
  }

  getStatus(status: string): string {
    switch (status) {
      case 'Confirmed':
        return this.translateService.instant('DOUGHNUT_CHART.CONFIRMED');
      case 'Rescheduled':
        return this.translateService.instant('DOUGHNUT_CHART.RESCHEDULED');
      case 'Processing':
        return this.translateService.instant('DOUGHNUT_CHART.PROCESSING');
      case 'Pending refund':
        return this.translateService.instant('DOUGHNUT_CHART.PENDING_REFUND');
      case 'Refunded':
        return this.translateService.instant('DOUGHNUT_CHART.REFUNDED');
      case 'Refund Failed':
        return this.translateService.instant('DOUGHNUT_CHART.REFUND_FAILED');
      case 'Canceled':
        return this.translateService.instant('DOUGHNUT_CHART.CANCELED');
      case 'Payment failed':
        return this.translateService.instant('DOUGHNUT_CHART.PAYMENT_FAILED');
      default:
        return status;
    }
  }

  formatColor(color: string): string {
    return '#' + color;
  }

  // calculateTotal(dataValues: any): number {
  //   return dataValues.reduce(
  //     (total: number, currentValue: number) => total + currentValue,
  //     0
  //   );
  // }
}
