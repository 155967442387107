import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
  @Input() chartTitle: string;
  @Input() chartData: any;
  @Input() yTickUnit: string;
  @Input() datasetsOptions: any;
  @Input() tickStepSize: number;
  @Input() tickSuggestedMax: number;
  data: any;
  options: any;

  ngOnInit() {
    this.data = {
      labels: this.chartData.items.current_items.map((item: any) => item.key),
      datasets: [
        {
          label: this.datasetsOptions[0].label,
          data: this.chartData.items.current_items.map(
            (item: any) => item.value
          ),
          fill: this.datasetsOptions[0].fill,
          borderColor: this.datasetsOptions[0].borderColor,
          backgroundColor: this.datasetsOptions[0].backgroundColor,
          borderDash: this.datasetsOptions[0].borderDash,
          tension: 0.3,
          pointRadius: 0,
          pointStyle: 'line',
          borderWidth: 2,
        },
        {
          label: this.datasetsOptions[1].label,
          data: this.chartData.items.previous_items.map(
            (item: any) => item.value
          ),
          fill: this.datasetsOptions[1].fill,
          borderColor: this.datasetsOptions[1].borderColor,
          backgroundColor: this.datasetsOptions[1].backgroundColor,
          borderDash: this.datasetsOptions[1].borderDash,
          tension: 0.1,
          pointRadius: 0,
          pointStyle: 'dash',
          borderWidth: 2,
        },
      ],
    };

    this.options = {
      maintainAspectRatio: false,
      responsive: true,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          display: false,
          align: 'start',
          labels: {
            usePointStyle: true,
            // color: '#101828',
            // pointStyle: 'dash',
            // pointStyleWidth: 25,
            padding: 12,
            font: {
              family: 'Inter',
              size: 14,
              weight: '500',
              color: '#F79009',
            },
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            borderColor: '#D0D5DD',
          },
          ticks: {
            color: '#344054',
            font: {
              size: 14,
              weight: '400',
              family: 'Inter',
            },
          },
        },
        y: {
          beginAtZero: true,
          suggestedMax: this.tickSuggestedMax,
          grid: {
            display: true,
            borderDash: [5, 5],
            offset: false,
            drawBorder: false,
            drawTicks: false,
          },
          ticks: {
            color: '#667085',
            stepSize: this.tickStepSize,
            padding: 10,
            font: {
              size: 12,
              weight: '400',
              family: 'Inter',
            },
            callback: (value: number, index: any, ticks: any) => {
              let lastValue = ticks[ticks.length - 1];
              if (value === lastValue.value) {
                return value + this.yTickUnit;
              }
              return value;
            },
          },
        },
      },
    };
  }
}
