import { Component, Input } from '@angular/core';

interface IContactInformation {
  contactName: string;
  email: string;
  phoneNumber: string;
}

@Component({
  selector: 'contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent {
  @Input() flightDetail: any;
  contactInformation: IContactInformation;

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.contactInformation = {
      contactName: 'Mr. Hoang Duy Minh',
      email: 'hdminh11@gmail.com',
      phoneNumber: '(+84) 365924762',
    };
  }
}
