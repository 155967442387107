<div class="w-full h-full border border-palette-gray-200 chart-component-shadow rounded-lg bg-white !px-6">
    <p class="!py-5 font-inter text-lg font-semibold text-palette-gray-900">{{'DOUGHNUT_CHART.BOOKING_STATUS' |
        translate}}</p>
    <div class="!pt-4 !pb-6 w-[100%]">
        <div class="font-inter card flex justify-content-center w-[100%]">
            <div class="flex !gap-2 flex-1"
                [ngClass]="{'flex-row-reverse': notePosition === 'left', 'flex-col': notePosition === 'bottom', 'flex-col-reverse': notePosition === 'top', 'flex-row, aling-center': notePosition === 'right'}">
                <div class="w-[50%] relative" [ngClass]="{'mx-auto': notePosition === 'bottom' || 'top'}">
                    <p-chart type="doughnut" [data]="data" [options]="options" width="320" height="320"></p-chart>
                    <div class="absolute text-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                        <p class="text-sm text-palette-gray-500">{{'DOUGHNUT_CHART.TOTAL_BOOKINGS' | translate}}</p>
                        <p class="text-2xl font-semibold text-palette-gray-900">{{totalBooking | number:'1.0-0'}}</p>
                    </div>
                </div>
                <div class="w-full h-full flex !items-center" *ngIf="dataReady&&totalBooking>0"
                    [ngClass]="{'!w-[75%] mx-auto': notePosition === 'bottom' || 'top'}">
                    <div class="flex flex-col !gap-2 w-full">
                        <div *ngFor="let item of dataValues; let i = index">
                            <div class="flex justify-between flex-1 w-[100%]">
                                <div class="flex items-center !gap-3">
                                    <div class="h-[10px] w-[10px] rounded-full"
                                        [ngStyle]="{'background-color': dataColors[i] }">
                                    </div>
                                    <p class="text-sm text-palette-gray-900">{{dataLabels[i]}}</p>
                                </div>
                                <div class="text-sm text-palette-gray-900 font-semibold">
                                    {{dataValues[i] | number:'1.0-0'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>