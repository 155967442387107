<div class="!flex flex-col !gap-2 !pb-10">
    <flight-schedule 
      [bookingInfo]="flightDetail.booking_info"
      [segments]="segments"
      [segment_type]="segment_type"
      [flightInfo]="flightDetail.flight_info">
    </flight-schedule>
    <app-guest-info type="RETURN" [flightDetail]="flightDetail" [segments]="segments"></app-guest-info>
    <fare-rule [fareRule]="fareRule"></fare-rule>
</div>
<app-toast></app-toast>