import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-show-image-modal',
  templateUrl: './show-image-modal.component.html',
  styleUrls: ['./show-image-modal.component.scss'],
})
export class ShowImageModalComponent {
  selectedImage: any;
  hotel_id: any;
  image_urls: any[] = [];
  all_image_urls: any[] = [];
  _activeIndex: number = 0;
  pageIndex = 1;
  pageSize = 20;
  // galleria: any[] = [
  //   'assets/images/test/detail-hotel-1.png',
  //   'assets/images/test/detail-hotel-2.png',
  //   'assets/images/test/detail-hotel-3.png',
  //   'assets/images/test/detail-hotel-4.png',
  //   'assets/images/test/detail-hotel-5.png',
  //   'assets/images/test/detail-hotel-6.png',
  //   'assets/images/test/detail-hotel-7.png',
  //   'assets/images/test/detail-hotel-2.png',
  //   'assets/images/test/detail-hotel-1.png',
  //   'assets/images/test/detail-hotel-2.png',
  //   'assets/images/home/banner/banner1.png',
  //   'assets/images/test/hotel-1.png',
  //   'assets/images/test/hotel-2.png',
  //   'assets/images/test/slideshow4.jpg',
  //   'assets/images/test/slideshow3.jpg',
  //   'assets/images/test/slideshow2.jpg',
  //   'assets/images/test/hotel-1.png',
  //   'assets/images/test/hotel-2.png',
  //   'assets/images/test/slideshow4.jpg',
  //   'assets/images/test/slideshow3.jpg',
  //   'assets/images/test/slideshow2.jpg',
  // ];
  get activeIndex(): number {
    return this._activeIndex;
  }

  set activeIndex(newValue) {
    if (
      this.image_urls &&
      0 <= newValue &&
      newValue <= this.image_urls.length - 1
    ) {
      this._activeIndex = newValue;
    }
  }

 
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 12,
    },
    {
      breakpoint: '768px',
      numVisible: 7,
    },
    {
      breakpoint: '370px',
      numVisible: 1,
    },
  ];

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    if (this.config.data) {
      this._activeIndex = this.config.data.image_id;
      this.all_image_urls = this.config.data.image_urls;
    }
    this.scrollImage();
  }
  ngOnInit() {    
    const page = Math.ceil(this._activeIndex/this.pageSize)
    for (let i = 1; i <= page; i++) {
      this.getImages(this.pageIndex, this.pageSize);
      this.pageIndex++;
    }
    this.loadMorePictures();
  }
  onclose() {
    this.ref.close();
  }
  next() {
    this.activeIndex++;
    this.scrollImage();
    this.loadMorePictures();
  }
  prev() {
    this.activeIndex--;
    this.scrollImage();
  }
  onMouseWheelImage(event: WheelEvent) {
    const container = document.querySelector('.image-item');
    if (container) {
        const delta = event.deltaY || event.detail;
        if(delta > 0)
          this.next();
        else if(delta < 0)
          this.prev();
        event.preventDefault();
    }
  }
  scrollImage() {
    const tixPos = document.querySelector(`#image-` + this.activeIndex);
    if (tixPos) {
      tixPos.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }
  handleImageScroll(index: number): void {
    this.activeIndex = index;
    this.scrollImage();
    this.loadMorePictures();
  }
  getImages(pageNum: number, pageSize: number){
    let value = this.all_image_urls.slice(((pageNum-1)*pageSize), pageNum*pageSize);
    this.image_urls = [...this.image_urls,...value]
  }
  loadMorePictures(){
    if(this.activeIndex >= this.image_urls.length - 15 && this.image_urls.length < this.all_image_urls.length)
    {
      this.getImages(this.pageIndex, this.pageSize);
      this.pageIndex +=1;
    }
  }
}
