import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-reschedule-booking',
  templateUrl: './reschedule-booking.component.html',
  styleUrls: ['./reschedule-booking.component.scss'],
})
export class RescheduleBookingComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  isShowReschedulePopup: boolean = true;
  isShowContinueButton: boolean = false;
  startDate: Date | null = null;
  endDate: Date | null = null;

  ngOnInit(): void {}

  closeReschedulePopup() {
    this.isShowReschedulePopup = false;
    this.close.emit();
  }
}
