import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-under-test',
  templateUrl: './under-test.component.html',
  styleUrls: ['./under-test.component.scss']
})
export class UnderTestComponent {

}
