<div class="bg-white rounded-lg border border-palette-gray-200 shadow-sm">
    <p class="!px-4 !pt-4 text-palette-gray-900 text-base font-semibold">{{ 'FLIGHT.MY_BOOKING.FLIGHT_SCHEDULE' |
        translate }}</p>
    <div *ngIf="segments?.length === 1"
        class="section relative !p-4 rounded-lg bg-white flex flex-col justify-center min-[1460px]:flex-row !gap-10">
        <div class="flex-1">
            <div class="flex items-center !gap-3">
                <div
                    class="!px-2 !py-[2px] font-inter text-xs font-medium bg-palette-blue-50 text-branding-primary-600 rounded flex items-center justify-center">
                    <ng-container *ngIf="segment_type === 'departure'; else returnTemplate">
                        {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.DEPART' | translate }}
                    </ng-container>
                    <ng-template #returnTemplate>
                        {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.RETURN' | translate }}
                    </ng-template>
                </div>
                <div class="flex items-center text-sm font-normal text-palette-gray-700">
                    <p class="!pr-3 border-r border-r-palette-gray-200">
                        {{segments[0].operating_name}} {{segments[0].aircraft}}</p>
                    <p class="!pl-3">{{segments[0].travel_class}}</p>
                </div>
            </div>
            <div class="!mt-3 pl-6 flex items-center !gap-6 relative">
                <div class="flex flex-col items-right gap-4">
                    <div class="flex flex-col gap-1 text-right w-[80px]">
                        <p class="text-sm font-medium text-palette-gray-700">
                            {{formatTime(segments[0].time_departure_at)}}</p>
                        <p class="text-xs text-palette-gray-500 font-normal">{{ segments[0].date_departure_at |
                            dateFormat }}</p>
                    </div>
                    <div class="flex flex-row-reverse text-right w-[80px]">
                        <img class="rounded" src="{{segments[0].operating_logo_url}}" alt="">
                    </div>
                    <div class="flex flex-col gap-1 text-right w-[80px]">
                        <p class="text-sm font-medium text-palette-gray-700">
                            {{formatTime(segments[0].time_arrival_at)}}</p>
                        <p class="text-xs text-palette-gray-500 font-normal">{{
                            segments[0].date_arrival_at | dateFormat}}</p>
                    </div>
                </div>
                <img class="w-[10px] h-[146px] rounded" src="assets/images/flight-view-details/divider-flight.svg"
                    alt="">
                <div class="flex flex-col items-right gap-4">
                    <div class="flex flex-col gap-1">
                        <p class="text-sm font-medium text-palette-gray-700">
                            {{segments[0].departure.city_name}}
                            ({{segments[0].departure.iata_code}})</p>
                        <p class="text-xs text-palette-gray-500 font-normal">
                            {{segments[0].departure.airport_name}}
                            {{segments[0].departure.terminal}}
                        </p>
                    </div>
                    <div class="text-sm font-normal text-palette-gray-700">
                        {{'FLIGHT.VIEW_DETAILS_SIDEBAR.DIRECT' | translate}}
                        • {{formatDuration(segments[0].duration, true)}}
                    </div>
                    <div *ngIf="segments[0].baggage_allowance">
                        <div class="flex flex-col !gap-2.5">
                            <div class="flex flex-row items-center !gap-2">
                                <i class="sctr-icon-luggage-02 text-xl text-palette-gray-500"></i>
                                <span class="font-inter text-sm font-normal text-palette-gray-900">
                                    {{ 'FLIGHT.SEARCH_RESULT.CABIN_BAGGAGE' | translate }} 
                                    {{ formatBaggages(segments[0].baggage_allowance?.cabin_baggage) }}
                                </span>
                            </div>
                            <div class="flex flex-row items-center !gap-2">
                                <i class="sctr-icon-luggage-01 text-xl text-palette-gray-500"></i>
                                <span class="font-inter text-sm font-normal text-palette-gray-900">
                                    {{ 'FLIGHT.SEARCH_RESULT.CHECKED_BAGGAGE' | translate }} 
                                    <ng-container *ngIf="formatBaggages(segments[0].baggage_allowance?.checked_baggage) == ''; else showBaggage">
                                        <span class="font-inter text-sm font-normal text-palette-gray-900 lowercase">
                                            {{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.NOT_INCLUDED'| translate}}
                                        </span>
                                    </ng-container>
                                    <ng-template #showBaggage>
                                        <span class="font-inter text-sm font-normal text-palette-gray-900">
                                            {{ formatBaggages(segments[0].baggage_allowance?.checked_baggage) }}
                                        </span>
                                    </ng-template>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-1">
                        <p class="text-sm font-medium text-palette-gray-700">
                            {{segments[0].arrival.city_name}}
                            ({{segments[0].arrival.iata_code}})</p>
                        <p class="text-xs text-palette-gray-500 font-normal">
                            {{segments[0].arrival.airport_name}}
                            {{segments[0].arrival.terminal}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden min-[1460px]:!block w-[1px] border border-dashed"></div>
        <div class="block min-[1460px]:!hidden h-[1px] border border-dashed"></div>
        <div class="flex flex-col items-center min-[1460px]:items-start">
            <h3 class="text-sm text-palette-gray-400"> {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.PNR' | translate }}</h3>
            <p class="!mt-4 text-xl font-bold text-palette-gray-700">
                <ng-container *ngIf="segment_type === 'departure'">
                    {{ bookingInfo?.reference_id || '---' }}
                </ng-container>
                <ng-container *ngIf="segment_type === 'arrival'">
                    {{ bookingInfo?.inbound_reference_id || '---' }}
                </ng-container>
            </p>
            <p class="mt-[2px] text-[10px] leading-5 text-palette-gray-400 italic"> {{
                'FLIGHT.VIEW_DETAILS_SIDEBAR.PNR_USED' | translate }}</p>
            <p class="!mt-4 text-sm text-palette-gray-400">{{'FLIGHT.VIEW_DETAILS_SIDEBAR.GDS_SOURCE' | translate}}</p>
            <p class="!mt-4 text-xl font-bold text-palette-gray-700">{{ flightInfo.source || '---' }}</p>
            
        </div>
       

    </div>
    <div *ngIf="segments?.length === 1" class="!p-4">
        <div class="flex flex-row !gap-2 !p-3 border border-palette-blue-600 rounded-xl bg-palette-blue-50">
            <i class="sctr-icon-info-circle text-palette-blue-600 text-xl"></i>
            <p class="text-palette-gray-700 font-normal text-sm">
                <span>{{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.DESCRIPTION' | translate}}</span>
                <span class="font-semibold">{{SOCTRIP_HOTLINE}}</span>
                <span>{{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.DESCRIPTION_END' | translate}}</span>
            </p>
        </div>
    </div>
    <div *ngIf="segments?.length !== 1"
        class="section !p-4 relative rounded-lg bg-white flex flex-col min-[1460px]:flex-row justify-center !gap-10">
        <div>
            <div class="flex items-center !gap-3">
                <div
                    class="!px-2 !py-[2px] font-inter text-xs font-medium bg-palette-blue-50 text-branding-primary-600 rounded flex items-center justify-center">
                    <ng-container *ngIf="segment_type === 'departure'; else returnTemplate">
                        {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.DEPART' | translate }}
                    </ng-container>
                    <ng-template #returnTemplate>
                        {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.RETURN' | translate }}
                    </ng-template>
                </div>
                <div class="flex items-center text-sm font-normal text-palette-gray-700">
                    <p class="!pl-3 border-l-2 border-l-palette-gray-200">{{segments[0]?.travel_class}}</p>
                </div>
            </div>
            <ng-container *ngFor="let item of segments; let first = first; let last = last">
                <div>
                    <div class="!mt-3">
                        <div class="flex !gap-6 items-center relative">
                            <div class="flex flex-col items-center !gap-4">
                                <div class="flex flex-col gap-1 text-right w-[80px]">
                                    <p class="text-sm font-medium text-palette-gray-700">
                                        {{formatTime(item.time_departure_at)}}</p>
                                    <p class="text-xs text-palette-gray-500 font-normal">{{ item.date_departure_at |
                                        dateFormat }}</p>
                                </div>
                                <div
                                    class="flex flex-row-reverse text-right w-[80px] text-sm font-normal text-palette-gray-700">
                                    {{formatDuration(item.duration, true)}}
                                </div>
                                <div class="flex flex-col gap-1 text-right w-[80px]">
                                    <p class="text-sm font-medium text-palette-gray-700">
                                        {{formatTime(item.time_arrival_at)}}</p>
                                    <p class="text-xs text-palette-gray-500 font-normal">{{ item.date_arrival_at |
                                        dateFormat }}</p>
                                </div>
                            </div>
                            <img class="w-[10px] h-[146px]" src="assets/images/flight-view-details/divider-flight-2.svg"
                                alt="">
                            <div class="flex flex-col !gap-4">
                                <div class="flex flex-col gap-1">
                                    <p class="text-sm font-medium text-palette-gray-700">{{item.departure.city_name}}
                                        ({{item.departure.iata_code}})</p>
                                    <p class="text-xs text-palette-gray-500 font-normal">
                                        {{item.departure.airport_name}} {{item.departure.terminal}}
                                    </p>
                                </div>
                                <div class="flex items-center !gap-2">
                                    <img class="" src="{{item.operating_logo_url}}" alt="">
                                    <div class="text-sm font-normal text-palette-gray-700">
                                        {{item.operating_name}} {{item.aircraft}}
                                    </div>
                                </div>
                                <div *ngIf="item.baggage_allowance">
                                    <div class="flex flex-col !gap-2.5">
                                        <div class="flex flex-row items-center !gap-2">
                                            <i class="sctr-icon-luggage-02 text-xl text-palette-gray-500"></i>
                                            <span class="font-inter text-sm font-normal text-palette-gray-900">
                                                {{ 'FLIGHT.SEARCH_RESULT.CABIN_BAGGAGE' | translate }} 
                                                {{ formatBaggages(item.baggage_allowance?.cabin_baggage) }}
                                            </span>
                                        </div>
                                        <div class="flex flex-row items-center !gap-2">
                                            <i class="sctr-icon-luggage-01 text-xl text-palette-gray-500"></i>
                                            <span class="font-inter text-sm font-normal text-palette-gray-900">
                                                {{ 'FLIGHT.SEARCH_RESULT.CHECKED_BAGGAGE' | translate }} 
                                                <ng-container *ngIf="formatBaggages(item.baggage_allowance?.checked_baggage) == ''; else showBaggage">
                                                    <span class="font-inter text-sm font-normal text-palette-gray-900 lowercase">
                                                        {{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.NOT_INCLUDED'| translate}}
                                                    </span>
                                                </ng-container>
                                                <ng-template #showBaggage>
                                                    <span class="font-inter text-sm font-normal text-palette-gray-900">
                                                        {{ formatBaggages(item.baggage_allowance?.checked_baggage) }}
                                                    </span>
                                                </ng-template>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-col gap-1">
                                    <p class="text-sm font-medium text-palette-gray-700">{{item.arrival.city_name}}
                                        ({{item.arrival.iata_code}})</p>
                                    <p class="text-xs text-palette-gray-500 font-normal">
                                        {{item.arrival.airport_name}} {{item.arrival.terminal}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!last"
                        class="!px-3 !py-1 bg-palette-blue-50 rounded font-inter flex flex-col !gap-1 !my-3">
                        <div class="text-xs font-medium text-palette-blue-light-600">
                            {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.TRANSFER_IN' | translate: { syntax: item.city_name_transfer,
                            syntax2: formatDuration(item.waiting_transfer, false) } }}
                        </div>
                        <div *ngIf="item.is_difference_terminal"
                            class="text-xs !text-[10px] font-normal text-palette-orange-dark-600">
                            {{'FLIGHT.VIEW_DETAILS_SIDEBAR.DIFFERENT_TERMINAL' | translate}}
                        </div>
                        <div *ngIf="!item.is_difference_terminal"
                            class="text-xs !text-[10px] font-normal text-palette-orange-dark-600">
                            {{'FLIGHT.VIEW_DETAILS_SIDEBAR.VISA_REQUIRED' | translate}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="hidden min-[1460px]:!block w-[1px] border border-dashed"></div>
        <div class="block min-[1460px]:!hidden h-[1px] border border-dashed"></div>
        <div class="flex flex-col items-center min-[1460px]:items-start justify-center">
            <h3 class="text-sm text-palette-gray-600"> {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.PNR' | translate }}</h3>
            <p class="!mt-4 text-xl font-bold text-palette-gray-700">
                <ng-container *ngIf="segment_type === 'departure'">
                    {{ bookingInfo?.reference_id || '---' }}
                </ng-container>
                <ng-container *ngIf="segment_type === 'arrival'">
                    {{ bookingInfo?.inbound_reference_id || '---' }}
                </ng-container>
            </p>
            <p class="mt-[2px] text-[10px] leading-5 text-palette-gray-600 italic"> {{
                'FLIGHT.VIEW_DETAILS_SIDEBAR.PNR_USED' | translate }}</p>
            <p class="!mt-4 text-sm text-palette-gray-400">{{'FLIGHT.VIEW_DETAILS_SIDEBAR.GDS_SOURCE' | translate}}</p>
            <p class="!mt-4 text-xl font-bold text-palette-gray-700">{{ flightInfo.source || '---' }}</p>
        </div>
    </div>
    <div *ngIf="segments?.length !== 1" class="!p-4">
        <div class="flex flex-row !gap-2 !p-3 border border-palette-blue-600 rounded-xl bg-palette-blue-50">
            <i class="sctr-icon-info-circle text-palette-blue-600 text-xl"></i>
            <p class="text-palette-gray-700 font-normal text-sm">
                <span>{{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.DESCRIPTION' | translate}}</span>
                <span class="font-semibold">{{SOCTRIP_HOTLINE}}</span>
                <span>{{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.DESCRIPTION_END' | translate}}</span>
            </p>
        </div>
    </div>
</div>