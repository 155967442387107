import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, of, Subject, Subscription, takeUntil, timeout } from 'rxjs';
import { TIME_OUT_REQUEST } from '@shared/constant';
import { FlightHelpdeskControllerService } from '@booking/angular-booking-admin-service';
import { ToastMessageService } from 'src/app/services/toast-message/toast-message.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss'],
})
export class ViewDetailsComponent {
  reservationId: string;
  bookingId: string;
  pinCode: string;
  onDestroy$: Subject<void> = new Subject();
  isLoading: boolean = true;
  currentModeView = 'departure';
  flightDetail: any;
  querySubscription: Subscription;
  flightReservationStorage: any;
  flightReservationControllerService: any;
  isShowChangeRequestHistoryPopup: boolean = false;
  changeRequestHistory: any;
  titleMap: any;
  returnTicket: boolean = false;
  pathChangeRequest: string = 'admin/flight-admin/booking-helpdesk/change-request';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private flightHelpdeskControllerService: FlightHelpdeskControllerService,
    private toastMessageService: ToastMessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.initData();
    this.querySubscription = this.activatedRoute.params.subscribe((params) => {
      this.bookingId = params['bookingId'] || '';
      if (this.bookingId) {
        this.callGetBookingDetail();
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initData() {
    this.titleMap = {
      ADDITIONAL_SERVICES_PURCHASING: this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.SERVICES_PURCHASE'),
      CANCEL_BOOKING: this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.CANCEL_BOOKING'),
      CHANGE_PASSENGER_NAME: this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.CHANGE_PASSENGER_NAME'),
      RESCHEDULE_FLIGHT: this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.RESCHEDULE_FLIGHT'),
    };
  }

  public callGetBookingDetail = () => {
    this.flightHelpdeskControllerService.searchListBookingDetails(this.bookingId)
      .pipe(
        timeout(TIME_OUT_REQUEST),
        takeUntil(this.onDestroy$),
        catchError((error) => {
          if (error.name === 'TimeoutError') {
            this.toastMessageService.showError('TIME_OUT_REQUEST');
          } else if (error.status === 0) {
            this.toastMessageService.showError('ERR_INTERNET_DISCONNECTED');
          } else {
            this.toastMessageService.showError(error.error.error);
          }
          return of(null);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response.success) {
          this.flightDetail = response.data;
          this.checkReturnFlight(this.flightDetail);
          this.bookingId = this.flightDetail?.booking_info.reservation_code;
        }
      });
  };

  checkReturnFlight(flightDetail: any) {
    this.returnTicket = flightDetail.flight_info.itineraries.some((itinerary: { segment_type: string; }) => itinerary.segment_type === "arrival");
  }

  onClickViewHistory() {
    this.isShowChangeRequestHistoryPopup = true;
  }

  closeChangeRequestHistoryPopup() {
    this.isShowChangeRequestHistoryPopup = false;
  }

  formatDateTime(dateTime: string): string {
    return moment(dateTime).format('HH:mm, MMM D, YYYY');
  }

  changeRequestStatusColor(status: string) {
    switch (status.toUpperCase()) {
      case 'SOLVED':
        return 'text-palette-emerald-600 bg-palette-emerald-50';
      case 'OPEN':
        return 'text-palette-gray-700 bg-palette-gray-100';
      default:
        return '';
    }
  }
}
