import { Injectable } from '@angular/core';
import { ToastType } from '@shared/enum/toast-type';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class ToastMessageService {
    constructor(private messageService: MessageService) { }

    showSuccess(detail: string) {
        this.messageService.add({
            severity: ToastType.SUCCESS,
            summary: 'Success',
            detail: detail,
        });
    }

    showWarning(detail: string) {
        this.messageService.add({
            severity: ToastType.WARNING,
            summary: 'Warning',
            detail: detail,
        });
    }

    showError(detail: string) {
        this.messageService.add({
            severity: ToastType.ERROR,
            summary: 'Error',
            detail: detail,
        });
    }
   

}
