<div class="fixed top-0 right-0 left-0 bottom-0 flex justify-center items-center bg-black bg-opacity-40">
    <div class="w-[588px] h-auto rounded-xl border border-palette-gray-200 popup-shadow bg-white !p-6 relative">
        <div class="flex justify-between mb-2">
            <span class="text-palette-gray-900 font-inter text-lg font-semibold leading-7">{{'FLIGHT.RESCHEDULE_BOOKING.TITLE' | translate}}</span>
            <button (click)="closeReschedulePopup()" class="flex justify-center items-center !w-7 !h-7 rounded-full hover:bg-palette-gray-100">
                <i class="sctr-icon-x-close text-xl leading-5"></i>
            </button>
        </div>
        <p class="text-palette-gray-700 font-inter text-sm font-normal leading-5 !mb-6">{{'FLIGHT.RESCHEDULE_BOOKING.CONFIRM' | translate}}</p>
        <div class="flex flex-col !gap-3">
            <div class="flex flex-col !gap-1 !p-4 border border-palette-gray-200 bg-palette-gray-25 rounded-lg">
                <p class="text-sm font-semibold text-palette-gray-900">{{'FLIGHT.RESCHEDULE_BOOKING.POLICY' | translate}}</p>
                <ul class="list-disc !ml-4 text-sm font-normal text-palette-gray-700">
                    <li>You have the flexibility to modify your flight dates up to three days before March 15, 2024. 
                        However, changes are not permitted within three days of the scheduled departure on March 15, 2024.</li>
                    <li>There may be a difference in fare when rescheduling is requested.</li>
                </ul>
            </div>
            <div class="ota-custom flex !gap-3 justify-between !mb-6">
                <div class="w-1/2">
                    <p class="text-palette-gray-700 text-sm font-medium leading-5">{{'FLIGHT.RESCHEDULE_BOOKING.SELECT_DEPARTING_FLIGHT' | translate}}</p>
                    <div class="custom-calendar !mt-1">
                        <p-calendar
                            [(ngModel)]="startDate"
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            [readonlyInput]="true"
                            [maxDate]="endDate!"
                            icon="sctr-icon-calendar text-palette-gray-500 !text-xl"
                            [showIcon]="true">
                        </p-calendar>
                    </div>
                </div>
                <div class="w-1/2">
                    <p class="text-palette-gray-700 text-sm font-medium leading-5">{{'FLIGHT.RESCHEDULE_BOOKING.SELECT_RETURNING_FLIGHT' | translate}}</p>
                    <div class="custom-calendar !mt-1">
                        <p-calendar
                            [(ngModel)]="endDate"
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            [readonlyInput]="true"
                            [minDate]="startDate!"
                            icon="sctr-icon-calendar text-palette-gray-500 !text-xl"
                            [showIcon]="true">
                        </p-calendar>
                    </div>
                </div>
            </div>
        </div>

        <!-- Buttons -->
        <div class="flex justify-end !gap-3 pt-2">
            <button (click)="closeReschedulePopup()" 
                class="keep-booking-btn !px-5 !py-3 rounded-lg text-sm font-semibold text-palette-gray-900 border border-palette-gray-300 hover:bg-palette-gray-100">
                {{'COMMON.CANCEL' | translate}}
            </button>
            <button (click)="closeReschedulePopup()"  
                class="{{startDate &&  endDate ? 'bg-palette-blue-600' : 'bg-branding-primary-200 cursor-not-allowed'}} !px-5 !py-3 mr-0 rounded-lg text-sm font-semibold text-white">
                {{'FLIGHT.RESCHEDULE_BOOKING.CONTINUE' | translate}}
            </button>
        </div>
    </div>
</div>