import { Component, Input } from '@angular/core';

interface IBookingInformation {
  referenceId: string;
  bookingId: string;
  pinCode: string;
  bookingDate: string;
  paymentMethod: string;
  paymentStatus: string;
  bookingStatus: string;
}
enum BOOKING_STATUS {
  CONFIRMED = 'Confirmed',
  CANCELED = 'Canceled',
  REFUNDED = 'Refunded',
  PROCESSING = 'Processing',
  PAYMENT_FAILED = 'Payment failed',
  RESCHEDULED = 'Rescheduled',
  PENDING_REFUND = 'Pending refund',
  REFUND_FAILED = 'Refund failed'
}

@Component({
  selector: 'booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.scss']
})
export class BookingInfoComponent {
  @Input() flightDetail: any;
  isShowPinCode: boolean = false;

  ngOnInit(): void {}


  handleClassBookingStatus(status: string) {
    switch (status) {
      case BOOKING_STATUS.CONFIRMED:
      case BOOKING_STATUS.RESCHEDULED:
        return 'bg-palette-emerald-50 text-palette-emerald-600';
      case BOOKING_STATUS.CANCELED:
      case BOOKING_STATUS.REFUNDED:
      case BOOKING_STATUS.PENDING_REFUND:
        return 'bg-palette-gray-100 text-palette-gray-700';
      case BOOKING_STATUS.PROCESSING:
        return 'bg-palette-amber-50 text-palette-amber-700';
      case BOOKING_STATUS.REFUND_FAILED:
      case BOOKING_STATUS.PAYMENT_FAILED:
        return 'bg-palette-red-50 text-palette-red-600';
      default:
        return '';
    }
  }

  hidePinCode(pinCode: string) {
    return pinCode?.replace(/./g, '•');
  }

  togglePinCode() {
    this.isShowPinCode = !this.isShowPinCode;
  }
}
