import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss']
})
export class CancelBookingComponent {

  showPassword: boolean = false;
  modalCancelBooking: boolean = true;
  modalConfirmCancel: boolean = false;
  modalSuccessfullyCancel: boolean = false;

  reasonChosen = '';
  reasonName: string = '';
  valueOtherReason: string;
  isShowButtonContinue: boolean = false;
  currentCurrency = localStorage.getItem('currency') || '';
  reason = '';
  @Output() closePopup = new EventEmitter<ElementRef>();

  list_reason = [
    {
      name: 'MY_BOOKING.CANCEL_BOOKING_POPUP.CHANGE_OF_TRAVELS_PLANS',
      value: 'change-of-travel-plans',
    },
    {
      name: 'MY_BOOKING.CANCEL_BOOKING_POPUP.DISSATISFACTION',
      value: 'dissatisfaction-with-the-booking',
    },
    {
      name: 'MY_BOOKING.CANCEL_BOOKING_POPUP.FOUND_BETTER_DEAL',
      value: 'found-a-better-deal',
    },
    {
      name: 'MY_BOOKING.CANCEL_BOOKING_POPUP.HAVE_ANOTHER_BOOKING',
      value: 'have-another-booking',
    },
    {
      name: 'MY_BOOKING.CANCEL_BOOKING_POPUP.PERSONAL_REASONS',
      value: 'personal-reasons',
    },
    {
      name: 'MY_BOOKING.CANCEL_BOOKING_POPUP.CALLED_OFF',
      value: 'the-trip-was-called-off',
    },
    {
      name: 'MY_BOOKING.CANCEL_BOOKING_POPUP.OTHER_REASON',
      value: 'other-reason',
    },
  ];

  public media: any = [];
  dragAreaClass: string;
  draggedFiles: any = [];
  mainPhoto: number = 0;
  allowedMimeTypes = ['image/png', 'image/jpeg'];
  imageUrls: string[] = [];
  isEditVisible: boolean = true;

  constructor(
    private translateService: TranslateService
  ){}

  handleClickCancelBooking() {
    this.modalCancelBooking = true;
  }

  handleClickConfirmCancel() {
    this.modalConfirmCancel = true;
  }

  handleSuccessfullyCanceled() {
    this.modalSuccessfullyCancel = true;
  }
  closeModalCancelBooking() {
    this.modalCancelBooking = false;
    this.reasonChosen = '';
    this.valueOtherReason = '';
    this.isShowButtonContinue = false;
    this.closePopup.emit();
  }
  hideModalCancelBooking() {
    this.modalCancelBooking = false;
    this.reasonChosen = '';
    this.valueOtherReason = '';
    this.isShowButtonContinue = false;
    
  }

  handleChooseReason(reason: any) {
    this.reason = reason;
    if (reason.value !== this.reasonChosen) {
      this.reasonChosen = reason.value;
      this.reasonName = reason.name;
      if (
        this.reasonChosen === '' ||
        this.reasonChosen === 'other-reason' ||
        this.reasonChosen === undefined
      ) {
        this.isShowButtonContinue = false;
      } else {
        this.isShowButtonContinue = true;
        this.valueOtherReason = '';
      }
    }
  }

  closeModalConfirmCancel() {
    this.modalConfirmCancel = false;
    document.body.style.overflow = '';
    this.closePopup.emit();
  }

  hideModalConfirmCancel() {
    this.modalConfirmCancel = false;
    document.body.style.overflow = '';
  }

  handleClickContinue() {
    if (this.reasonChosen === 'other-reason')
      this.reasonName = this.valueOtherReason;
    else this.reasonName = this.translateService.instant(this.reasonName);
    this.modalCancelBooking = false;
    this.modalConfirmCancel = true;
    document.body.style.overflow = 'hidden';
  }

  handleClickConfirmCancelBooking() {
    this.modalConfirmCancel = false;
    this.modalSuccessfullyCancel = true;
  }
  handleClickDone() {
    this.modalSuccessfullyCancel = false;
    //this.router.navigate(['my-booking/hotel']);
    this.closePopup.emit();
  }

  onInputChange(e: any) {
    if (this.valueOtherReason === undefined || this.valueOtherReason === '') {
      this.isShowButtonContinue = false;
    } else {
      this.isShowButtonContinue = true;
    }
  }

  onDelete(index: number) {
    this.draggedFiles.splice(index, 1);
    if (index === this.mainPhoto) {
      this.mainPhoto = 0;
    } else if (index < this.mainPhoto) {
      this.mainPhoto--;
    }
  }

  onChoose(index: number){
    this.mainPhoto = index;
  }

  onDragOver(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  onDragLeave(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  onDrop(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();  
    let files: FileList = event.dataTransfer.files;
    this.addPhotos(files);
  }

  onFileChange(event: any) {
    let files: FileList = event.target.files;
    this.addPhotos(files);
    event.stopPropagation()
  }

  async addPhotos(files: any) {
    let fileTypeIsValid: boolean = false;
    let fileSizeIsValid: boolean = false;
    let imageSizeIsValid: boolean = false;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = window.URL.createObjectURL(file);
        if (!this.isFileTypeAllowed(file.type)) {
          fileTypeIsValid = true;
          continue;
        }
        if (!this.isFileSizeValid(file.size)) {
          fileSizeIsValid = true;
          continue;
        }  
        const image = new Image();
        image.src = url;
  
        const imageSizeValid = await this.isImageSizeValidAsync(url);
        if (!imageSizeValid) {
          imageSizeIsValid = true;
        } else {
          this.draggedFiles.push({ file, url });
        }
      }
    }
  }
  
  async isImageSizeValidAsync(url: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const image = new Image();
      image.src = url;
  
      image.onload = () => {
        if (!this.isImageSizeValid(image)) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
    });
  }
  isFileTypeAllowed(fileType: string): boolean {
    const allowedMimeTypes = ['image/png', 'image/jpeg'];
    return allowedMimeTypes.includes(fileType);
  }
  
  isFileSizeValid(fileSize: number): boolean {
    const minSize = 10 * 1024; // 10 KB
    const maxSize = 20 * 1024 * 1024; // 20 MB
    return fileSize >= minSize && fileSize <= maxSize;
  }
  
  isImageSizeValid(image: HTMLImageElement): boolean {
    const minWidth = 800;
    const minHeight = 600;  
    return (
      image.width >= minWidth &&
      image.height >= minHeight
    );
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.svg';
  }

  formatPrice(price: number): string {
    const formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: this.currentCurrency,
    }).format(price);
    return formattedPrice;
  }

}
