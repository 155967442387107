import { HttpClient } from '@angular/common/http';
import { AuthApi } from './auth/api.auth';
import { TranslateApi } from './translate/api.translate';
import { ApiClientConfig } from './api-client.config';

export class ApiClient {
    readonly auth: AuthApi;
    readonly translate: TranslateApi;

    constructor(private http: HttpClient, config: ApiClientConfig) {
        this.auth = new AuthApi(this.http, config);
        this.translate = new TranslateApi(this.http, config);
    }
}
