<div class="flex justify-center items-center !mx-auto flight-cancel-booking absolute top-1/2 -translate-y-1/2 right-1/2 translate-x-1/2 rounded-lg">    
    <!-- Modal Cancel booking -->
    <div class="card flex justify-content-center rounded-lg">
        <div class="w-[588px] h-auto max-h-[90vh] bg-white p-4 rounded-lg overflow-auto" *ngIf="modalCancelBooking">
            <div class="flex justify-between !pb-6">
               <div class="flex flex-col !gap-2 font-inter">
                    <p class="text-lg font-semibold text-palette-gray-900">{{'MY_BOOKING.CANCEL_BOOKING' | translate}}</p>
                    <p class="text-sm font-normal text-palette-gray-700">{{'FLIGHT.CANCEL_BOOKING.CANCEL_BOOKING_DESCRIPTION' | translate}}</p>
               </div>
                <i (click)="closeModalCancelBooking()" class="sctr-icon-x-close text-xl leading-5 text-palette-gray-700 cursor-pointer"></i>
            </div>
            <div class="flex flex-col !gap-4 !mb-8">
                <div class="!m-0">
                    <div class="w-[100%] !p-4 border border-palette-gray-200 bg-palette-gray-25 rounded-lg flex flex-col !gap-1 font-inter">
                        <p class="text-sm font-semibold text-palette-gray-900">{{'FLIGHT.CANCEL_BOOKING.CANCELLATION_POLICY' | translate}}</p>
                        <p class="text-sm font-normal text-palette-gray-700">If you cancel your booking three or more days prior to March 15, 2024, you will incur a $88. However, no refunds will be issued if the cancellation is made within three days of the departure date on March 15, 2024</p>
                    </div>
                </div>
                <div class="flex flex-col !gap-3">
                    <div class="flex !gap-1">
                        <p class="font-inter text-sm font-medium text-palette-gray-700">{{'FLIGHT.CANCEL_BOOKING.SELECT_A_REASON' | translate}}</p>
                        <p class="font-inter text-sm font-semibold text-system-error-600">*</p>
                    </div>
                    <div class="flex flex-col !gap-3">
                        <div *ngFor="let item of list_reason" class="flex !gap-2">
                            <p-radioButton name="optionTrip"
                                (onClick)="handleChooseReason(item)"
                                [value]="item"
                                [(ngModel)]="reason"
                                class="items-center font-inter text-sm font-normal text-palette-gray-900"></p-radioButton>
                            <span (click)="handleChooseReason(item)"  class="font-inter text-sm font-normal text-palette-gray-900 cursor-pointer">{{item.name | translate}}</span>
                        </div> 
                    </div>
                    <div *ngIf="reasonChosen === 'other-reason'" class="flex flex-col !gap-1">
                        <div class="flex !gap-1">
                            <p class="font-inter text-sm font-medium text-palette-gray-700">{{'FLIGHT.CANCEL_BOOKING.DESCRIPTION' | translate}} </p>
                            <p class="font-inter text-sm font-semibold text-system-error-600">*</p>
                        </div>
                        <div class="card flex justify-content-center">
                            <textarea class="other-reason-textarea w-[100%] !h-[94px] rounded-lg !py-3 !px-[14px] font-inter text-base text-palette-gray-900"
                                maxlength="256"
                                placeholder="{{'PAYMENT.PLACEHOLDER.ENTER_A_DESCRIPTION' | translate}}" 
                                pInputTextarea 
                                [(ngModel)]="valueOtherReason" 
                                (ngModelChange)="onInputChange($event)" 
                                >
                            </textarea>
                        </div>
                    </div>
                </div>
                <!-- Photo  -->
                <div class="flex flex-col !gap-3 justify-center items-start">
                    <input type="file"
                        #file
                        [multiple]="true"
                        (change)="onFileChange($event)"
                        style="display:none" 
                        accept=".jpg, .png, .heic"/>
                    <div class="w-full flex items-center justify-between !gap-1">
                        <p class="font-inter text-sm font-medium text-palette-gray-700">
                            {{'COMMON.ATTACHMENTS' | translate}}  ({{ draggedFiles.length }} / 4)</p>
                        <p *ngIf="draggedFiles.length > 0" (click)="file.click()" class="font-inter text-base font-semibold text-palette-blue-600 cursor-pointer">
                            {{'FLIGHT.CANCEL_BOOKING.UPLOAD_MORE' | translate}}</p>
                    </div>
                    <div *ngIf="draggedFiles.length === 0" class="hover-element flex flex-col justify-center items-center w-full h-full rounded-xl border border-palette-gray-300 cursor-pointer"
                        ngClass="{{dragAreaClass}}"
                        (dragover)="onDragOver($event)"
                        (dragleave)="onDragLeave($event)" 
                        (drop)="onDrop($event)"
                        (click)="file.click()"
                        (mouseover)="onDragOver($event)"
                        (mouseleave)="onDragLeave($event)">
                        <div class="flex flex-col justify-center items-center !gap-3 !px-6 !py-4">
                            <div class="flex justify-center items-center !w-8 h-8 p-2 rounded-full border-4 iconStyle">
                                <i class="sctr-icon-upload-cloud-01 !w-4 !h-4"></i>
                            </div>
                            <div class="flex flex-col items-center justify-center !gap-1">
                                <div class="flex">
                                    <a class="text-palette-blue-600 !text-sm font-inter font-medium" href="javascript:void(0)" >{{'FLIGHT.CANCEL_BOOKING.CLICK_UPLOAD' | translate}}</a>
                                    <p class="!text-sm font-inter font-normal text-palette-gray-700">&nbsp;{{'FLIGHT.CANCEL_BOOKING.DRAG' | translate}}</p>
                                </div>
                                <p class="text-palette-gray-500 text-[12px] font-inter font-normal leading-4">{{'FLIGHT.CANCEL_BOOKING.ACCEPTS' | translate}}</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="draggedFiles.length > 0" class="flex flex-col !gap-3">
                        <div class="flex gap-2 !m-0">
                            <div *ngFor="let file of draggedFiles; let idx = index" class="relative min-w-[100px] max-w-[130px] aspect-square cursor-pointer">
                                <i class="sctr-icon-x-close text-[12px] flex items-center justify-center absolute 
                                    text-white h-[16px] !w-[16px] p-0.5 top-[8px] right-[8px] bg-[#10182866] rounded-full" (click)="onDelete(idx)"></i>
                                <img *ngIf="file" [src]="file.url"
                                    class="w-full h-full object-cover rounded-lg">
                            </div>
                        </div>
                    </div>      
                </div>
            </div>
            <div class="flex items-center justify-end !gap-3">
                <button
                    class="keep-booking-btn h-[44px] px-[18px] py-[10px] 
                        rounded-lg font-inter text-sm font-semibold text-palette-gray-900 border-1 border-palette-gray-300"
                    (click)="closeModalCancelBooking()">{{'FLIGHT.CANCEL_BOOKING.KEEP_BOOKING' | translate}}
                </button>
                <button
                    (click)="handleClickContinue()"
                    class="h-[44px] px-[18px] py-[10px] mr-0 rounded-lg font-inter text-sm font-semibold text-white
                    {{isShowButtonContinue ? 'bg-branding-primary-600' : 'bg-branding-primary-200 cursor-auto pointer-events-none'}}">
                        {{'FLIGHT.CANCEL_BOOKING.CONTINUE' | translate}}</button>
            </div>
        </div>
    </div>

    <!-- Modal Confirm Cancellation -->
    <div class="card flex justify-content-center rounded-lg">
        <div class="w-[588px] h-auto max-h-[90vh] bg-white p-4 rounded-lg" *ngIf="modalConfirmCancel">
            <div class="flex justify-between !pb-6">
                <div class="flex flex-col gap-2 font-inter">
                    <p class="text-lg font-semibold text-palette-gray-900">{{'FLIGHT.CANCEL_BOOKING.CONFIRM_CANCELLATION' | translate}}</p>
                    <p class="text-sm font-normal text-palette-gray-700">{{'FLIGHT.CANCEL_BOOKING.CONFIRM_CANCELLATION_DESCRIPTION' | translate}}</p>
                </div>
                <i (click)="closeModalConfirmCancel()" class="sctr-icon-x-close text-xl leading-5 text-palette-gray-700 cursor-pointer"></i>
            </div>
            <div class="!m-0 !mb-8 bg-white">
                <div class="flex flex-col !gap-4 rounded-md">
                    <div class="flex !gap-3 w-full">
                        <p class="!w-[67px] h-[20px] flex justify-start !py-0.5 !px-2 
                            font-inter text-[10px] font-semibold leading-4 text-white bg-palette-blue-600 rounded whitespace-nowrap">
                            Round trip</p>
                        <div class="w-full flex items-center !gap-2">
                            <div class="w-1/2 flex flex-col !gap-1">
                                <p class="font-inter text-sm font-medium text-palette-gray-900">Ho Chi Minh -> Hue</p>
                                <div class="flex !gap-1">
                                    <p class="font-inter text-xs font-medium leading-4 text-palette-gray-500">08:30 - 10:00</p>
                                    <p class="font-inter text-xs font-medium leading-4 text-palette-gray-500">•</p>
                                    <p class="font-inter text-xs font-medium leading-4 text-palette-gray-500">Thu, 18 Mar 2024</p>
                                </div>
                            </div>
                            <div class="w-[1px] h-6 items-center bg-palette-gray-200"></div>
                            <div class="w-1/2 flex flex-col !gap-1">
                                <p class="font-inter text-sm font-medium text-palette-gray-900">Hue -> Ho Chi Minh </p>
                                <div class="flex !gap-1">
                                    <p class="font-inter text-xs font-medium leading-4 text-palette-gray-500">08:30 - 10:00</p>
                                    <p class="font-inter text-xs font-medium leading-4 text-palette-gray-500">•</p>
                                    <p class="font-inter text-xs font-medium leading-4 text-palette-gray-500">Sun, 21 Mar 2024</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between !gap-4">
                        <p class="font-inter text-sm font-semibold text-palette-gray-500 leading-5">{{'FLIGHT.CANCEL_BOOKING.PRICE_BOOKING' | translate}}</p>
                        <p class="font-inter text-sm font-semibold text-palette-gray-900 leading-5">{{formatPrice(123456)}}</p>
                    </div>
                    <div class="h-[0px] w-full border-1 border-dashed border-palette-gray-200"></div>
                    <div class="flex flex-col !gap-2">
                        <div class="flex justify-between items-center font-inter">
                            <p class="text-base font-semibold text-palette-gray-900">{{'MY_BOOKING.CANCELLATION_FEE' | translate}}</p>
                            <p class="text-base font-semibold text-palette-gray-900">88$</p>
                        </div>
                        <common-icon-text class="!p-2 flex items-center !gap-2 cursor-pointer bg-palette-gray-100 rounded-lg"
                            [icon]="'sctr-icon-alert-circle'"
                            [iconStyle]="'text-palette-gray-700 !w-4 !h-4 flex items-center'"
                            [text]="'You will be charged $88.00 as cancellation fee'"
                            [textStyle]="'text-palette-gray-700 font-normal font-inter text-sm whitespace-nowrap leading-4'"></common-icon-text>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-end !gap-3">
                <button
                    class="keep-booking-btn h-[44px] px-[18px] py-[10px] rounded-lg font-inter text-sm font-semibold text-palette-gray-900 border-1 border-palette-gray-300"
                    (click)="closeModalConfirmCancel()">{{'FLIGHT.CANCEL_BOOKING.KEEP_BOOKING' | translate}}</button>
                <button
                    (click)="handleClickConfirmCancelBooking()"
                    class="h-[44px] px-[18px] py-[10px] mr-0 rounded-lg font-inter text-base font-semibold text-white bg-system-error-600"
                    >{{'MY_BOOKING.CANCEL_BOOKING' | translate}}</button>
            </div>
        </div>
    </div>

    <!-- Modal Successfully Canceled -->
    <div class="card flex justify-content-center rounded-lg overflow-hidden">
        <div class="w-[588px] h-auto max-h-[90vh] bg-white p-4" *ngIf="modalSuccessfullyCancel">
            <div class="flex items-center justify-between !pb-6">
                <div class="flex flex-col gap-1 font-inter ">
                    <p class="text-lg font-semibold text-palette-gray-900">{{'FLIGHT.CANCEL_BOOKING.SUCCESSFULLY_CANCELED' | translate}}</p>
                    <p class="text-sm font-normal text-palette-gray-700">{{'FLIGHT.CANCEL_BOOKING.SUCCESSFULLY_CANCELED_DESCRIPTION' | translate}}</p>
                </div>
                <i (click)="handleClickDone()" class="sctr-icon-x-close text-xl leading-5 text-palette-gray-700 cursor-pointer"></i>
            </div>
            <div class="flex flex-col !gap-3 !mb-8">
                <div class="w-full rounded-md flex flex-col gap-3">
                    <div class="flex items-center gap-3">
                        <div class="h-4 aspect-square flex justify-center items-center">
                            <i class="sctr-icon-check-circle-broken text-base text-system-success-500"></i>
                        </div>
                        <p class="font-inter text-sm font-normal text-palette-gray-900">Your booking is successfully canceled for free</p>
                    </div>
                    <div class="flex !gap-3 font-inter">
                        <div class="h-4 aspect-square flex justify-center items-center">
                            <i class="sctr-icon-check-circle-broken text-base text-system-success-500"></i>
                        </div>
                        <div class="flex !gap-1">
                            <p class="text-sm font-normal text-palette-gray-900">
                                {{'FLIGHT.CANCEL_BOOKING.SUCCESSFULLY_CANCELLATION_NOTICE' | translate}}
                            </p>
                            <span class="text-sm font-semibold text-palette-gray-900"> hdminh11@gmail.com</span>
                        </div>
                    </div>                    
                </div>
            </div>
            <div class="flex items-center justify-end !gap-3">
                <button
                    class="keep-booking-btn !m-0 h-[44px] px-[18px] py-[10px] rounded-lg font-inter text-sm font-semibold text-palette-gray-900 border-1 border-palette-gray-300"
                    (click)="handleClickDone()">{{'COMMON.CLOSE' | translate}}</button>
            </div>
        </div>
    </div>
</div>