import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss'],
})
export class GuestInfoComponent {
  @Input() type: string;
  @Input() flightDetail: any;
  @Input() segments: any;

  key: string;

  ngOnInit() {
    if (this.type === 'DEPART') {
      this.key = 'departure';
    } else {
      this.key = 'return';
    }
  }

  public isNumber(value: any): boolean {
    return typeof value === 'number';
  }
}
