import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { MulTranslationService } from 'src/app/core/services/mul-translation/mul-translation.service';
import { TranslateParamModel } from 'src/app/lib/api/translate/api.translate.model';
import { ToastType } from '@shared/enum/toast-type';
import { MAX_TRUNCATED_TEXT_HEIGHT, MAX_TRUNCATED_TEXT_HEIGHT_COMMENT } from '@shared/constant';

@Component({
  selector: 'app-view-departure-mode',
  templateUrl: './view-departure-mode.component.html',
  styleUrls: ['./view-departure-mode.component.scss'],
  providers: [MessageService],
})
export class ViewDepartureModeComponent {
  @Input() flightDetail: any;

  baggageAllowance: any;
  segments: any;
  segment_type: string;
  fareRule: string;
  textTranslated: string;
  contentHeight = 0;
  isTranslate = false;
  isShowAllContent = false;
  MAX_TRUNCATED_TEXT_HEIGHT_COMMENT = MAX_TRUNCATED_TEXT_HEIGHT_COMMENT;
  MAX_TRUNCATED_TEXT_HEIGHT = MAX_TRUNCATED_TEXT_HEIGHT;
  isLiveView = false;
  isEdit = false;
  constructor(
    private messageService: MessageService,
    private mulTranslationService: MulTranslationService,
    private soctripTranslationService: SoctripTranslationService,
  ) { }

  ngOnInit() {
    const departureItinerary = this.flightDetail.flight_info?.itineraries.find((itinerary: { segment_type: string; }) => itinerary.segment_type === "departure");
    if (departureItinerary) {
      this.baggageAllowance = departureItinerary.segments[0].baggage_allowance;
      this.segments = departureItinerary.segments;
      this.segment_type = departureItinerary.segment_type;
      this.fareRule = departureItinerary.fare_rule;
    }
  }

  capitalizeFirstLetterAfterLiTags(string: string) {
    let html = string.replace(/<li\b[^>]*>([^<]*)<\/li>/g, (match, content) => {
      if (!/<\/?[a-z][\s\S]*>/i.test(content.trim())) {
        content = content.charAt(0).toUpperCase() + content.slice(1).toLowerCase();
        return `<li>${content}</li>`;
      }
      return match;
    });
    html = html.replace(/\s{5}(?=\d|-)/g, '<br>&emsp;');
    html = html.replace(/\s{3,}/g, ' ');
    html = html.replace(/-{3,}/g, match => {
      return `<br>${match}<br>`;
    });
    html = html.replace(/\n/g, '<br>');
    html = html.replace(/(<br\s*\/?>\s*){2,}/g, '<br>');
    html = html.replace(/(<br\s*\/?>){2,}/g, '<br>');


    // if(html.length > 1600){
    //   this.viewMore = true;
    //   this.collapse = false;
    // this.fullHTML = html;
    // this.collapseHTML = html.substring(0, 1600);
    // html = this.collapseHTML += '...'
    // }
    return html;
  }

  onTranslateText() {
    this.textTranslated = '';
    const selectedLangCode = this.mulTranslationService
      .getKeyLocalUserProfile('language')
      ?.toLowerCase();
    this.isTranslate = !this.isTranslate;
    this.getTranslateText(selectedLangCode?.toLowerCase() || 'en');
    if (this.contentHeight > MAX_TRUNCATED_TEXT_HEIGHT) {
      this.isShowAllContent = true;
    }
  }

  getTranslateText(target: string) {
    let content = this.fareRule;
    content = this.capitalizeFirstLetterAfterLiTags(content);
    const params: TranslateParamModel = {
      q: content.trim(),
      source: 'auto', // TODO: Implement dectect language when `comment.language` is implemented
      target: target,
      format: 'html',
    };
    this.mulTranslationService.translateText(params).subscribe((res: any) => {
      if (res && res.success) {
        this.textTranslated = res?.data.translatedText;
      } else {
        this.isTranslate = false;
        this.messageService.add({
          severity: ToastType.SUCCESS,
          detail: this.soctripTranslationService.getTranslation('TRANSLATION.TRANSLATE_FAILED'),
        });
      }
    });
  }

  onExpandText() {
    this.isShowAllContent = !this.isShowAllContent;
  }

  markHashtagsForTranslation(content: string) {
    let tempConent = content;
    tempConent = tempConent?.replace(/\n/g, '<br />');
    return tempConent;
  }

  formattedContent() {
    let content = this.fareRule;
    content = this.capitalizeFirstLetterAfterLiTags(content);
    content = this.replaceLinkTag(content);
    content = this.mulTranslationService.urlify(content);
    return content;
  }

  replaceLinkTag(content: string) {
    let tempConent = content;
    return tempConent;
  }

  handleChangeLanguage(event: any) {
    this.textTranslated = '';
    this.getTranslateText(event.value.code);
  }

  translateContent() {
    return this.textTranslated;
  }

  isShowTranslateButton(fareRule: any) {
    if (!this.formattedContent()) return false;
    return this.mulTranslationService.checkLanguage(
      fareRule,
      'en'
    );
  }
}
