<div class="relative">
    <div class="relative">
        <i *ngIf="icon" [ngClass]="icon" class="absolute left-4 top-3 z-20"></i>
        <input
            #input
            type="search"
            class="suggest-search {{ class }} {{ icon ? 'has-icon' : ''}}"
            [id]="id"
            [formControl]="control"
            [placeholder]="placeholder | translate"
            autocomplete="off"
            [ngClass]="ngClass"
            (input)="onInputChange($event)"
            (focus)="onFocus()"
            (blur)="onBlur()"
        />
    </div>
    <!-- Suggest Search Items -->
    <ng-container *ngIf="isShowSuggestions">
        <div class="absolute top-[46px] left-0 rounded-lg bg-white !p-1 z-[99] shadow-lg min-w-full max-w-[460px] max-h-[280px] overflow-auto">
            <ng-content></ng-content>
        </div>
    </ng-container>
    
</div>