<div *ngIf="isShowBaggage" class="border-1 border-palette-gray-200 rounded-lg bg-white !p-4 font-inter">
    <div (click)="handleClickExpandCollapse(expandBaggage)" (mouseover)="handleMouseOverLeave(expandBaggage, true)" (mouseleave)="handleMouseOverLeave(expandBaggage, false)" 
        class="flex items-center justify-between cursor-pointer">
        <div class="flex items-center !gap-4">
            <img class="!h-[56px] aspect-square" src="assets/images/flight-payment/Baggage.svg" alt="">
            <p [ngClass]="{'!font-extrabold':expandBaggage.isMouseOver}" class="text-lg font-semibold text-palette-gray-900">
                {{'FLIGHT.EXTRA_BAGGAGE.EXTRA_BAGGAGE' | translate}}</p>
        </div>
        <i [ngClass]="{'sctr-icon-chevron-down':expandBaggage.isExpand, 'sctr-icon-chevron-right':!expandBaggage.isExpand, 'font-extrabold':expandBaggage.isMouseOver}"
            class="text-xl leading-5 text-palette-gray-500"></i>
    </div>
    <div @slideDownUp *ngIf="expandBaggage.isExpand"
        class="!grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 !mt-4">
        <div *ngFor="let segment of segments" class="!p-4 border border-palette-gray-200 bg-palette-gray-50 rounded-md">
            <div class="flex items-center !gap-2 text-base !font-medium text-palette-gray-900">
                <span>{{ segment?.departure?.iata_code }}</span>
                <i class="sctr-icon-arrow-narrow-right text-[20px]"></i>
                <span>{{ segment?.arrival?.iata_code }}</span>
            </div>
            <div class="flex flex-col !gap-y-2 !mt-2">
                <div class="flex justify-between" *ngFor="let persion of passengerInfo">
                    <span class="font-inter text-sm !font-normal text-palette-gray-700">{{ persion?.title }}. {{ persion?.last_name }} {{ persion?.middle_and_first_name }}</span>
                    <span *ngIf="persion?.extra_baggage?.weight" class="font-inter text-sm font-medium text-palette-gray-900">{{ persion?.extra_baggage?.weight}}kg</span>
                    <span *ngIf="!(persion?.extra_baggage?.weight)" class="font-inter text-sm font-medium text-palette-gray-900">Not included</span>
                </div>
            </div>
        </div>
    </div>
</div>