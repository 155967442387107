import { Component } from '@angular/core';

@Component({
  selector: 'banner-reservation-flight',
  templateUrl: './banner-reservation-flight.component.html',
  styleUrls: ['./banner-reservation-flight.component.scss']
})
export class BannerReservationFlightComponent {

}
