<div *ngIf="isShowMeal" class="border-1 border-palette-gray-200 rounded-lg bg-white !p-4 font-inter">
    <div (click)="handleClickExpandCollapse(expandMeal)" (mouseover)="handleMouseOverLeave(expandMeal, true)"
        (mouseleave)="handleMouseOverLeave(expandMeal, false)"
        class="flex items-center justify-between cursor-pointer">
        <div class="flex items-center !gap-4">
            <img class="!h-[56px] aspect-square" src="assets/images/flight-payment/meal.svg" alt="">
            <p [ngClass]="{'!font-extrabold':expandMeal.isMouseOver}"
                class="text-lg font-semibold text-palette-gray-900">
                {{'FLIGHT.PAYMENT.MEAL_SELECTION' | translate}}</p>
        </div>
        <i [ngClass]="{'sctr-icon-chevron-down':expandMeal.isExpand, 'sctr-icon-chevron-right':!expandMeal.isExpand, 'font-extrabold':expandMeal.isMouseOver}"
            class="text-xl leading-5 text-palette-gray-500"></i>
    </div>
    <div @slideDownUp *ngIf="expandMeal.isExpand"
        class="!grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 !mt-4">
        <div *ngFor="let segment of segments" class="!p-4 border border-palette-gray-200 bg-palette-gray-50 rounded-md">
            <div class="flex items-center !gap-2 text-base !font-medium text-palette-gray-900">
                <span>{{ segment?.departure?.iata_code }}</span>
                <i class="sctr-icon-arrow-narrow-right text-[20px]"></i>
                <span>{{ segment?.arrival?.iata_code }}</span>
            </div>
            <!-- <div class="flex flex-col !gap-y-2 !mt-2">
                <div class="flex justify-between" *ngFor="let persion of passengerInfo">
                    <span class="font-inter text-sm !font-normal text-palette-gray-700">{{ persion?.title }}. {{ persion?.last_name }} {{ persion?.middle_and_first_name }}</span>
                    <span class="font-inter text-sm font-medium text-palette-gray-900">{{ persion?.seat?.code || 'Not included' }}</span>
                </div>
            </div> -->
        </div>
    </div>
</div>