<div class="!p-4 bg-white rounded-lg border border-palette-gray-200 shadow-sm">
    <p class="text-palette-gray-900 text-base font-semibold">{{ 'FLIGHT.MY_BOOKING.GUEST_INFORMATION' | translate }}</p>
    <!-- TABLE PASSENGER -->
    <div class="relative overflow-x-auto !mt-2 border !rounded-lg border-palette-gray-200 !font-medium">
        <table class="min-w-full">
            <thead
                class="text-sm leading-[39.5px] text-palette-gray-700 !rounded-t-lg bg-palette-gray-100">
                <tr class="border-b">
                    <td class="rounded-tl-lg sticky left-0 z-10 bg-palette-gray-100 whitespace-nowrap">{{ 'FLIGHT.PAYMENT.PASSENGER' | translate }}</td>
                    <td class="whitespace-nowrap">{{ 'FLIGHT.PAYMENT.SEAT' | translate }}</td>
                    <td class="whitespace-nowrap">{{ 'FLIGHT.PAYMENT.EXTRA_BAGGAGE_1' | translate }}</td>
                    <td class="rounded-tr-lg whitespace-nowrap">{{ 'FLIGHT.PAYMENT.MEAL' | translate }}</td>
                </tr>
            </thead>
            <tbody>
                <ng-container
                    *ngFor="let passenger of flightDetail?.passenger_information; let last = last">
                    <tr class="text-xs leading-5 text-palette-gray-700" [class.border-b]="!last">
                        <td class="py-1 sticky left-0 z-10 bg-white whitespace-nowrap">
                            <p class="uppercase font-medium">{{ passenger.middle_and_first_name }} {{ passenger.last_name }}</p>
                            <p class="text-palette-gray-500 font-normal">
                                {{ 'FLIGHT.PAYMENT.' + passenger.passenger_type.toUpperCase() | translate }}
                            </p>
                        </td>
                        <td class="py-1 w-fit">
                            <ng-container *ngIf="passenger[key+'_seat']?.length; else noSeat">
                                <div *ngFor="let segment of passenger[key+'_seat']; let lastItem = last" class="w-fit {{!lastItem ? 'mb-1' : ''}}">
                                    <p class="text-palette-gray-700 font-medium whitespace-nowrap">
                                        {{segment.origin_iata_code}} -> {{segment.destination_iata_code}}
                                    </p>
                                    <p class="text-palette-gray-500 whitespace-nowrap">{{ segment.code }}</p>
                                </div>
                            </ng-container>
                            <ng-template #noSeat>
                                <div class="w-[14px] h-[2px] border border-palette-gray-300"></div>
                            </ng-template>
                        </td>
                        <td class="py-1 w-fit">
                            <ng-container *ngIf="passenger[key+'_extra_baggage']?.length; else noBaggage">
                                <div *ngFor="let segment of passenger[key+'_extra_baggage']; let lastItem = last" class="w-fit {{!lastItem ? 'mb-1' : ''}}">
                                    <p class="text-palette-gray-700 font-medium whitespace-nowrap">
                                        {{segment.origin_iata_code}} -> {{segment.destination_iata_code}}
                                    </p>
                                    <p class="text-palette-gray-500 whitespace-nowrap">
                                        {{ segment.weight }} {{ segment.unit }}
                                    </p>
                                </div>
                            </ng-container>
                            <ng-template #noBaggage>
                                <div class="w-[14px] h-[2px] border border-palette-gray-300"></div>
                            </ng-template>
                        </td>
                        <td class="py-1 w-fit">
                            <ng-container *ngIf="passenger[key+'_meals']?.length; else noMeal">
                                <ng-container *ngFor="let segment of passenger[key+'_meals']; let lastItem = last">
                                    <div *ngIf="segment.meals.length" class="w-fit {{!lastItem ? 'mb-1' : ''}}">
                                        <p class="text-palette-gray-700 font-medium whitespace-nowrap">
                                            {{segment.origin_iata_code}} -> {{segment.destination_iata_code}}
                                        </p>
                                        <p *ngFor="let meal of segment.meals; let last = last" class="text-palette-gray-500 font-normal whitespace-nowrap">
                                            {{ meal.meal_name }} x{{ meal.quantity }}<span *ngIf="!last">, </span>
                                        </p>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #noMeal>
                                <div class="w-[14px] h-[2px] border border-palette-gray-300"></div>
                            </ng-template>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>