import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { debounceTime, Subject } from 'rxjs';

@Component({
    selector: 'app-suggest-search',
    templateUrl: './suggest-search.component.html',
    styleUrls: ['./suggest-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SuggestSearchComponent),
            multi: true
        }
    ]
})
export class SuggestSearchComponent implements ControlValueAccessor {
    @Input() icon: string = '';
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() class: string = '';
    @Input() ngClass: any = '';
    @Input() formControlName: string = '';
    @Output() onInputEmit = new EventEmitter();
    @Output() onFocusEmit = new EventEmitter();
    @Output() onBlurEmit = new EventEmitter();
    
    isShowSuggestions: boolean = false;

    value: any = '';
    onChange: (value: any) => void = () => { };
    onTouched: () => void = () => {};

    private debounceTimer: any;

    // Getter and Setter for the value
    get control(): FormControl {
        return this._control;
    }

    set control(val: FormControl) {
        this._control = val;
        this.value = val.value;
    }

    private _control: FormControl = new FormControl();

    writeValue(value: any): void {
        this.value = value;
        if (this._control) {
            this._control.setValue(value, { emitEvent: false });
        }
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        // Handle disable logic if needed
    }

    // Function to call when input changes
    onInputChange(event: any) {
        this.value = event.target.value;
        this.onChange(this.value);
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(() => {
            this.onInputEmit.emit();
        }, 300);
    }

    onFocus() {
        this.isShowSuggestions = true;
        this.onFocusEmit.emit();
    }

    onBlur() {
        this.onTouched();
        this.isShowSuggestions = false;
        this.onBlurEmit.emit();
    }
}
