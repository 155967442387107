import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { CancelBookingComponent } from "./cancel-booking/cancel-booking.component";
import { RescheduleBookingComponent } from './reschedule-booking/reschedule-booking.component';
import { DialogModule } from 'primeng/dialog';

@Component({
    selector: 'app-price-details',
    templateUrl: './price-details.component.html',
    styleUrls: ['./price-details.component.scss'],
})
export class PriceDetailsComponent implements OnInit {
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;
  isShowCancelPopup: boolean = false;
  isShowReschedulePopup: boolean = false;
  serviceData = [
    {
      numSeat: 0,
      totalSeat: 0,
      numMeal: 0,
      totalMeal: 0,
      numExtraBaggage: 0,
      totalExtraBaggage: 0,
    },
    {
      numSeat: 0,
      totalSeat: 0,
      numMeal: 0,
      totalMeal: 0,
      numExtraBaggage: 0,
      totalExtraBaggage: 0,
    }
  ];
  collapsedStates: boolean[] = [];

  @Input() flightDetail: any;

  ngOnInit(): void {
    if (this.flightDetail?.price_details?.price_details?.length) {
      this.collapsedStates = new Array(this.flightDetail.price_details.price_details.length).fill(true); // Initialize collapsed states
    }
  }

  showCancelPopup(){
    this.isShowCancelPopup = true;
  }

  closeCancelPopup(){
    this.isShowCancelPopup = false;
  }

  showReschedulePopup() {
    this.isShowReschedulePopup = true;
  }

  closeReschedulePopup() {
    this.isShowReschedulePopup = false;
  }

  toggleCollapse(index: number) {
    this.collapsedStates[index] = !this.collapsedStates[index];
  }
}
